> .header {
  background-image: url('../images/headers/moja-plantacja.jpg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media (min-width: @screen-md-min) {
    height: 277px;
    background-size: cover;
  }

  .logo {
    display: inline-block;
    margin-top: 50px;
  }

  .banner {
    position: relative;
    height: 100%;

    .full-size {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .logged-user {
    height: 100%;
    padding-top: 10px;
    color: @colorBlack;

    .header {
      text-transform: uppercase;
      border-bottom: 10px solid transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      a {
        color: @colorBlack;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .menu {
      a {
        color: @colorBlack;
        text-decoration: none;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 2px solid transparent;

        span {
          display: inline-block;
          font-size: 10px;
          text-transform: uppercase;
        }

        &:hover {
          border-color: @colorBlack;
        }

        &.pos {
          padding-bottom: 0;
        }

        &.messages {
          position: relative;

          .badge-container {
            display: inline-block;
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
            text-align: center;

            .badge {
              background: transparent;
              color: @colorBlack;
            }
          }


        }
      }
    }

    .logout {
      margin-top: 5px;
      margin-bottom: 5px;

      .btn {
        background-color: @colorBlack;
        border-color: @colorBlack;
        color: @colorWhite;

        &:hover {
          background-color: darken(@colorBlack, 2%);
        }
      }
    }

    .footer {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &.moja-plantacja{
    background-image: url('../images/headers/moja-plantacja.jpg');
  }

  &.zasady-programu,
  &.zasady-programu-zasady,
  &.zasady-programu-digitalizacja {
    background-image: url('../images/headers/zasady-programu.jpg');
  }

  &.serwisy{
    background-image: url('../images/headers/serwisy.jpg');
  }

  &.akcje,
  &.akcje-wczesna-sprzedaz,
  &.akcje-akcja-ala,
  &.akcje-toprex-10-1,
  &.akcje-elumis-forte,
  &.akcje-elumis,
  &.akcje-fungipak-ziemniak,
  &.akcje-akcja-aktywacja,
  &.akcje-bonus-za-wzrost,
  &.akcje-maximalna-promocja,
  &.akcje-wiosenne-przyspieszenie,
  &.akcje-chorus,
  &.akcje-score,
  &.akcje-yecla,
  &.akcje-cieta-promocja,
  &.akcje-oferta-duo,
  &.akcje-bon-fungicydowy,
  &.akcje-kup-nasiona,
  &.akcje-scorpion,
  &.akcje-geoxe,
  &.akcje-produkty-na-plus,
  &.akcje-certicor,
  &.akcje-prysnij,
  &.akcje-zasiej,
  &.akcje-zasiej-rzepak,
  &.akcje-seguris-opti {
    background-image: url('../images/headers/akcje.jpg');
  }

  &.akcje-kalendarium {
    background-image: url('../images/headers/akcje-kalendarium.jpg');
  }

  &.nagrody-nowosci {
    background-image: url('../images/headers/nagrody-nowosci.jpg');
  }
  &.nagrody-dla-twojego-biznesu {
    background-image: url('../images/headers/nagrody-dla-twojego-biznesu.jpg');
  }
  &.nagrody-wyjazdy-chile {
    background-image: url('../images/headers/nagrody-wyjazdy-chile.jpg');
  }
  &.nagrody-wyjazdy-hiszpania {
    background-image: url('../images/headers/nagrody-wyjazdy-hiszpania.jpg');
  }
  &.nagrody-spa {
    background-image: url('../images/headers/nagrody-spa.jpg');
  }
  &.nagrody-sklep-internetowy {
    background-image: url('../images/headers/nagrody-sklep-internetowy.jpg');
  }
  &.nagrody-karty-podarunkowe {
    background-image: url('../images/headers/nagrody-karty-podarunkowe.jpg');
  }
  &.nagrody-meble {
    background-image: url('../images/headers/nagrody-meble.jpg');
  }
  &.nagrody-kupony-premiowe {
    background-image: url('../images/headers/nagrody-kupony-premiowe.jpg');
  }
  &.nagrody-vouchery {
    background-image: url('../images/headers/nagrody-vouchery.jpg');
  }
  &.nagrody-marketingowe {
    background-image: url('../images/headers/nagrody-marketingowe.jpg');
  }
  &.nagrody-karta-przedplacona {
    background-image: url('../images/headers/nagrody-karta-przedplacona.jpg');
  }
  &.nagrody-bony-produktowe {
    background-image: url('../images/headers/nagrody-bony-produktowe.jpg');
  }
  &.nagrody-concierge {
    background-image: url('../images/headers/nagrody-concierge.jpg');
  }

  &.filmy,
  &.wazne-informacje {
    background-image: url('../images/headers/aktualnosci.jpg');
  }

  &.plantacja-wiedzy {
    background-image: url('../images/headers/plantacja-wiedzy.jpg');
  }

  &.produkty-premiowane-sor,
  &.produkty-premiowane-nasiona-polowe,
  &.produkty-premiowane-warzywa  {
    background-image: url('../images/headers/produkty-premiowane.jpg');
  }

  &.dystrybutorzy-sor,
  &.dystrybutorzy-nasiona-polowe,
  &.dystrybutorzy-warzywa {
    background-image: url('../images/headers/dystrybutorzy.jpg');
  }

  &.przedstawiciele-handlowi-sor,
  &.przedstawiciele-handlowi-nasiona-polowe,
  &.przedstawiciele-handlowi-warzywa {
    background-image: url('../images/headers/przedstawiciele-handlowi.jpg');
  }
}
