@font-face {
  font-family: 'Noto Sans Thin';
  font-weight:100;
  font-style:normal;
  src: url('fonts/NotoSansDisplay-Thin.ttf?raw=true') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Regular';
  font-weight:400;
  font-style:normal;
  src: url('fonts/NotoSansDisplay-Regular.ttf?raw=true') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Black';
  font-weight:900;
  font-style:normal;
  src: url('fonts/NotoSansDisplay-Black.ttf?raw=true') format('truetype');
}

.font-thin{
  font-family: 'Noto Sans Thin';
}

.font-black{
  font-family: 'Noto Sans Black';
}

.font-regular{
  font-family: 'Noto Sans Regular';
}

@fontFamily: 'Noto Sans Regular', serif;
