.thead {
  display: block;
  border-bottom: 2px solid #DDD;
  padding: 5px 0;
  font-weight: bold;
}

.tbody {
  display: block;
  border-top: 1px solid #DDD;
  padding: 5px 0;
  line-height: 20px;
  font-size: 12px;

  &:hover {
    background-color: #F5F5F5;
  }

  .status-active span {
    line-height: 25px;
    display: inline-block;
    width: 100%;
    border-left: 25px solid #43A146;
    background: rgba(67, 161, 70, 0.10);
    color: #43A146;

    &:before {
      font-family: FontAwesome;
      content: "\f00c";
      color: #ffffff;
      float: left;
      margin-left: -20px;
      font-weight: normal;
    }
  }

  .status-inactive span {
    line-height: 25px;
    display: inline-block;
    width: 100%;
    border-left: 25px solid #DF1E25;
    background: rgba(223, 30, 37, 0.10);
    color: #DF1E25;

    &:before {
      font-family: FontAwesome;
      content: "\f00d";
      color: #ffffff;
      float: left;
      margin-left: -18px;
      font-weight: normal;
    }
  }

  .status-transition span {
    line-height: 25px;
    display: inline-block;
    width: 100%;
    border-left: 25px solid #df842d;
    background: rgba(223, 30, 37, 0.10);
    color: #df842d;

    &:before {
      font-family: FontAwesome;
      content: "\f00d";
      color: #ffffff;
      float: left;
      margin-left: -18px;
      font-weight: normal;
    }
  }

  .status-confirm span {
    line-height: 25px;
    display: inline-block;
    width: 100%;
    border-left: 25px solid #42BFEA;
    background: rgba(66, 191, 234, 0.10);
    color: #42BFEA;

    &:before {
      font-family: FontAwesome;
      content: "\f017";
      color: #ffffff;
      float: left;
      margin-left: -20px;
      font-weight: normal;
    }
  }

  .status-confirmed span {
    line-height: 25px;
    display: inline-block;
    width: 100%;
    border-left: 25px solid #EC7C23;
    background: rgba(236, 124, 35, 0.10);
    color: #EC7C23;

    &:before {
      font-family: FontAwesome;
      content: "\f012";
      color: #ffffff;
      float: left;
      margin-left: -18px;
      font-weight: normal;
    }
  }

  .status-active,
  .status-inactive,
  .status-confirm,
  .status-confirmed {
    a {
      position: absolute;
      top: 0;
      right: 20px;
    }
  }
}

.tfoot {
  display: block;
  border-top: 2px solid #DDD;
  padding: 5px 0;
  font-weight: bold;
}

.thead .icon,
.tbody .icon {
  position: relative;
  top: 4px;
  font-size: 20px;
}

.tfilter {
  line-height: 35px;
}

.tgroup:hover {
  .tbody {
    &:extend(.tbody:hover);
  }
}