h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @fontFamily;
  font-weight: bold;
  text-transform: uppercase;

  &.underline, .underline {
    display: inline-block;
    border-bottom: 10px solid @colorMenu;
    margin-bottom: 35px;
  }

  @media (max-width: @screen-sm-max) {
    &.underline, .underline {
      margin-bottom: 0;
    }
  }
}

.text-larger {
  font-size: 20px;
}

.special-margin {
    margin: 20px 0;
}