.btn {
  .border-radius(0);
  text-transform: uppercase;
  font-weight: bold;

  &.btn-default {
    background: @colorBlack;
    color: @colorWhite;

    &:hover {
      background: lighten(@colorBlack, 10%);
    }
  }

  &.btn-primary {
    background: @colorMenu;
    color: @colorWhite;
    border-color: @colorMenu;

    &:hover {
      background: lighten(@colorMenu, 10%);
    }
  }

  &.btn-danger {
    background: @colorBlack;
    color: @colorWhite;
    border-color: @colorBlack;

    &:hover {
      background: lighten(@colorBlack, 10%);
    }
  }
  
  &.btn-warning {
    background: @colorRed;
    color: @colorWhite;
    border-color: @colorRed;

    &:hover {
      background: lighten(@colorRed, 10%);
    }
  }

  &.btn-orange {
    background: @colorOrange;
    color: @colorWhite;
    border-color: @colorOrange;

    &:hover {
      background: lighten(@colorOrange, 10%);
    }
  }
}
