form {
  .error-block {
    display: none;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #b94a48;

    > span {
      clear: both;
      float: left;
    }
  }

  .has-error .error-block {
    display: block;
  }
}

input {
  .border-radius(0) !important;
}

.form-control.ui-select-bootstrap {
  padding: 0;

  .btn.btn-default {
    background: none;
    color: @colorBlack;
  }
}

.fileinput-button {
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
  }
}

.Zebra_DatePicker {
  z-index: 1100 !important;
}