@-webkit-keyframes pulse {
    0% { background-color: @colorOrange; }
    50% { background-color: #000; }
    100% { background-color: @colorOrange; }
}

@-webkit-keyframes pulse-gold {
  0% { background-color: @colorOrange; }
  50% { background-color: #000; }
  100% { background-color: @colorOrange; }
}

> .menu {
  margin-bottom: 10px;
  background-color: @colorMenu;
  text-align: center;

  a {
    position: relative;
    display: block;
    color: @colorWhite;
    min-height: 32px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: @colorMenu;

    &.pulse {
      color: @colorWhite;
      -webkit-animation: pulse 1s infinite;
    }

    @media (min-width: @screen-md-min) {
      display: inline-block;
    }

    > .pointer {
      position: absolute;
      bottom: -10px;
      width: 100%;
      height: 10px;
      margin-left: -15px;
      .transition(all 0.5s ease-in-out);
      z-index: 1002;

      > .arrow {
        border-width: 0;
        border-style: solid;
        font-size: 0;
        line-height: 0;
        margin: 0 auto;
        width: 0;
        left: 0;
        .transition(all 0.5s ease-in-out);
        border-color: @colorMenu transparent transparent transparent;
      }
    }

    &:hover,
    &.active {
      > .pointer > .arrow {
        border-width: 10px;
        .transition(all 0.5s ease-in-out);
      }
    }

    &.nagrody,
    &.podroze,
    &.produkty-premiowane,
    &.dystrybutorzy,
    &.przedstawiciele-handlowi {
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        line-height: 50px;
      }
    }
  }
}

> .menu-sub {
  min-height: 31px;
  border-bottom: 1px solid @colorGrey;

  div.separ {
      width: 80px;
      display: inline-block;
  }

  a {
    font-size: 10px;
    line-height: 30px;
    font-weight: bold;
    color: @colorBlack;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 8px;
    white-space: nowrap;
    margin: 0 2px;

    &:hover,
    &.active {
      background: @colorMenu;
      color: @colorBlack;
    }

    &.not-active-green {
      color: @colorGreen;
    }
    &.not-active-green:hover,
    &.active-green {
      background: @colorGreen;
      color: @colorWhite;
    }

    &.not-active-orange {
      color: @colorOrange;
    }
    &.not-active-orange:hover,
    &.active-orange {
      background: @colorOrange;
      color: @colorWhite;
    }

    &.pulse {
        color: @colorWhite;
        -webkit-animation: pulse 1s infinite;
    }

    &.pulse-gold {
      color: @colorWhite;
      -webkit-animation: pulse-gold 1s infinite;
    }
  }
}
