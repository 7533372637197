[ng-cloak] {
  display: none;
}

html, body {
  font-family: @fontFamily;
  background: @colorWhite;
  color: @colorBlack;
  height: 100%;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.full-height {
  height: 100vh;
}

.blind {
  color: #fff;

  .blind-mobile {
    .logo {
      margin: 20px 0;
    }

    .photo {
      border-radius: 40px;
    }

    .block {
      background-color: #AFCA0B;
      padding: 20px;
      border-radius: 40px;
      margin-bottom: 20px;

      &.leaf-1 {
        background-image: url('../images/strony/blind/leaf.png');
        background-size: 35% auto;
        background-repeat: no-repeat;
        background-position: right 10px top;
      }

      &.leaf-2 {
        background-image: url('../images/strony/blind/leaf.png');
        background-size: 35% auto;
        background-repeat: no-repeat;
        background-position: left 10px top;
      }

      p {
        padding: 10px 0;
      }

      .btn-info {
        background-color: #174290;
        border-color: #174290;
        border-radius: 15px;
        padding: 15px;
        margin-bottom: 20px;
      }
    }
  }

  .visible-xl {
    display: none;
  }

  @media (min-width: 1920px) and (min-height: 990px) {
    .visible-xl {
      display: block !important;
    }

    .hidden-xl {
      display: none !important;
    }
  }

  .blind-desktop {
    .outer-container {
      height: 980px; // zamiast klasy full-height
      background-image: url('../images/strony/blind/bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
      position: relative;

      .inner-container {
        position: absolute;
        top: 5%;
        left: 6%;

        .logo {
          width: 190px;
        }

        h1 {
          padding-top: 8%;
          margin-bottom: 0;
          font-size: 80px;
          font-weight: bold;
          letter-spacing: 2px;
        }

        h2 {
          margin-top: 0;
          font-size: 50px;
          font-weight: bold;
          letter-spacing: 2px;
        }

        h4 {
          padding-top: 2%;
          padding-bottom: 8%;
          font-size: 40px;
          font-weight: normal;
          text-transform: none;
        }

        .contact {
          div.part {
            display: inline-block;
            vertical-align: top;
            padding-right: 20px;

            .title {
              font-size: 20px;
            }
          }
        }

        .btn {
          display: block;
          margin-bottom: 20px;
          width: 200px;
          border-radius: 15px;
          padding: 15px;
          font-size: 16px;
        }

        .btn-info {
          background-color: #FACC2E;
          border-color: #FACC2E;
        }

        p {
          padding-top: 12%;
          margin-bottom: 3%;
          font-size: 22px;
        }

        .btn-warning {
          background-color: #2CA639;
          border-color: #2CA639;
        }
      }
    }

    .footer-outer-container {
      position: relative;

      .footer-inner-container {
        position: absolute;
        left: 7%;
        right: 7%;

        * {
          color: #6E6E6E;
        }

        .first {
          padding-top: 20px;
          padding-bottom: 10px;
          border-bottom: 2px #6E6E6E solid;
        }

        .second {
          padding-top: 10px;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left, .right {
            width: 48%;
            box-sizing: border-box;
          }

          .right {
            text-align: right;

            * {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

body > .container {
  position: relative;
  min-height: 100%;
  border-left: 1px solid @colorGrey;
  border-right: 1px solid @colorGrey;

  > * {
    display: none;
  }

  .app-loading {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;

    .info {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
    }
  }

  .outdated-browser {
    display: none;
    padding-top: 50px;
    max-width: 1170px;

    a {
      display: block;
      line-height: 50px;
      font-size: 20px;
      text-decoration: none;
      margin: 10px 0;
      color: @colorBlack;

      img {
        margin-right: 10px;
      }

      .btn {
        margin-top: 8px;
      }
    }
  }

  &.not-supported {
    .outdated-browser {
      display: block;
    }

    .app-loading {
      display: none !important;
    }
  }

  &.ready {
    > * {
      display: block;
    }

    .outdated-browser,
    .app-loading {
      display: none !important;
    }
  }

  @import "header";
  @import "menu";
  @import "main";
}

body.admin-navbar > .container {
  padding-top: 50px;
  padding-bottom: 35px; // wysokość slidera
}

#cookie {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  line-height: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  font-size: 12px;
  z-index: 2000;

  div {
    margin: 0 auto;
    padding: 10px;
    max-width: 980px;
  }

  a.closeCookie {
    background: #666;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
    padding: 5px 10px;
    margin: 0 0 0 20px;
  }
}

.right-stick {
  position: fixed;
  right: 0;
  top: 50vh;
  transform: translateY(-110px);

  .countdown-right {
    margin-bottom: 15px;
    width: 15rem;
    height: 13rem;
    color: #fff;
    background: rgb(211,99,44);
    background: -moz-linear-gradient(0deg, rgba(211,99,44,1) 0%, rgba(245,128,58,1) 100%);
    background: -webkit-linear-gradient(
            0deg
            , rgba(211,99,44,1) 0%, rgba(245,128,58,1) 100%);
    background: linear-gradient(
            0deg
            , rgba(211,99,44,1) 0%, rgba(245,128,58,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d3632c",endColorstr="#f5803a",GradientType=1);
    border-radius: 10px 0 0 10px;
    padding-left: 1rem;

    .title {
      padding: 8px 8px 4px 8px;
      font-weight: bold;
      font-size: 12px;
    }

    .element {
      width: 62px;
      margin-bottom: 20px;

      ~ .element {
        margin-left: 8px;
      }

      .number {
        font-size: 2.5rem;
        text-align: center;
        color: #fff;
        position: relative;
        z-index: 10;
        height: 5rem;
        line-height: 5rem;
        font-family: sans-serif;

        &:before,
        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          right: 0;
          left: 0;
          height: 2.5rem;
        }

        &:before {
          top: 0;
          background-color: rgba(0, 0, 0, .13);
          border-radius: 3px 3px 0 0;
        }

        &:after {
          bottom: 0;
          background-color: rgba(255,255,255, .13);
          border-radius: 0 0 3px 3px;
        }

        .border {
          position: absolute;
          height: 10px;
          top: 50%;
          transform: translateY(-5px);
          left: 0;
          right: 0;

          &:before,
          &:after {
            content: '';
            width: 4px;
            height: 100%;
            display: block;
            position: absolute;
            background: rgb(201,213,228);
            background: -moz-linear-gradient(0deg, rgba(201,213,228,1) 0%, rgba(239,242,247,1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(201,213,228,1) 0%, rgba(239,242,247,1) 100%);
            background: linear-gradient(0deg, rgba(201,213,228,1) 0%, rgba(239,242,247,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c9d5e4",endColorstr="#eff2f7",GradientType=1);
            border: 1px solid #b5bfcc;
          }

          &:before {
            left: 0;
          }

          &:after {
            right: 0;
          }
        }
      }
      .name {
        margin-top: 2px;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .elatus-era {
    float: right;
    width: 70px;
    height: 107px;
    background-image: url('../images/elatus-era-stick.jpg');
  }

  .agriclub {
    float: right;
    width: 60px;
    height: 60px;
    background-image: url('../images/agriclub-stick.png');
  }
}

.card {
  background-image: url('../images/card/card.png');
  height: 320px;
  margin-top: 10px;

  .description {
    padding: 80px 45px 0 45px;

    strong {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    img {
      max-height: 20px;
      margin: 8px 0 8px 15px;
    }
  }
}

.prysnij {
  .logo {
    padding-top: 50px;
    padding-bottom: 60px;
    background: url('../images/akcje/prysnij/bg.png') no-repeat;
    background-size: cover;
  }

  .feature {
    div[class^='col'] {
      padding-left: 5px;
      padding-right: 5px;
    }

    .icon {
      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .title {
      color: #879AB1;
      font-weight: bold;
      font-size: 1.4em;
      line-height: 1em;
    }
  }

  .action {
    padding-top: 40px;

    .title-outer {
      width: 80%;
      margin-left: 10%;

      .title-inner {
        color: #fff;
        background-color: #274392;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        font-size: 1.2em;
        line-height: 1em;
        font-weight: bold;
        padding: 15px 0;
      }
    }

    .description {
      position: relative;
      color: #fff;
      background-color: #6C85A3;
      border-radius: 30px;
      padding: 20px 15px 65px 15px;

      ul {
        padding-inline-start: 20px;
      }

      hr {
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .footer {
        position: absolute;
        bottom: 20px;
        width: 88%;

        a {
          color: #fff;
        }
      }
    }
  }
}

.serwisy {
  ul.mojeinfopole {
    li {
      margin-bottom: 20px;
    }
  }
}

.marquee {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 35px;  // wysokość slidera
  overflow: hidden;
  padding: 5px;
  background: #f4af16;
  color: #fff;
  font-size: 1.7rem;
  white-space: nowrap;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 35s linear infinite;
}

.marquee span:hover {
  animation-play-state: paused
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.modal-body {
  max-height: 90vh;
  overflow-y: auto;
}
