a {
  color: @colorBlack;

  &:hover {
    color: @colorBlack;
  }
}

.col-center-block {
  float: none;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.produkty {
  max-width: 100%;
}

.distributor {
  .tooltip-inner {
    max-width: 230px;
    width: 230px;
  }

  .type-digi {
    background-color: #E87D23;
    //background-color: #244976;
  }

  .type-no-digi {
    background-color: #E87D23;
    //background-color: #4B9574;
  }

  .type-no-digi-lg {
    background-color: #E87D23;
    //background-color: #FF7F28;
  }

  .type-digi-wheat {
    background-color: #E87D23;
    //border: 5px solid #B5E61D;
    //color: #000 !important;
    //line-height: 1.3 !important;
  }

  .type-digi-and-digi-wheat {
    background-color: #E87D23;
    //background-color: #244976;
    //border: 5px solid #B5E61D;
    //line-height: 1.3 !important;
  }

  .type-no-digi-and-digi-wheat {
    background-color: #E87D23;
    //background-color: #4B9574;
    //border: 5px solid #B5E61D;
    //line-height: 1.3 !important;
  }

  .map {
    position: relative;
    width: 811px;

    .point {
      position: absolute;
      width: 42px;
      height: 42px;
      font-weight: bold;
      color: #fff;
      font-size: 1.6em;
      line-height: 1.7em;
      border-radius: 50%;
      cursor: pointer;

      &.small {
        width: 26px;
        height: 26px;
        font-size: 1.2em;
        line-height: 1.3em;
      }
    }

    .point-green-square {
      position: absolute;
      width: 34px;
      height: 34px;
      font-weight: bold;
      background-color: #119945;
      color: #fff;
      font-size: 1.6em;
      line-height: 1.3em;
      cursor: pointer;

      &.small {
        width: 22px;
        height: 22px;
        font-size: 1.2em;
        line-height: 1.3em;
      }
    }

    .legend {
      position: absolute;
      bottom: 6px;

      .item {
        margin-bottom: 10px;

        .number {
          margin: 0 20px 0 0;
        }

        .name {
          display: inline-block;
        }
      }
    }
  }

  .title {
    color: #E87D23;
    padding-bottom: 20px;
  }

  .title-green {
    color: #119945;
    padding-bottom: 20px;
  }

  .number {
    display: inline-block;
    width: 35px;
    height: 35px;
    font-weight: bold;
    color: #fff;
    font-size: 1.4em;
    line-height: 1.6em;
    border-radius: 50%;
    margin: 0 15px 15px 20px;

    &.indentation {
      margin-left: 70px;
    }

    &.type-digi-wheat {
      //line-height: 1.1em !important;
    }
  }

  .number-green-square {
    display: inline-block;
    width: 35px;
    height: 35px;
    font-weight: bold;
    background-color: #119945;
    color: #fff;
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 0 15px 15px 20px;

    &.indentation {
      margin-left: 70px;
    }

    &.type-digi-wheat {
      //line-height: 1.1em !important;
    }

    //~.name-type-digi {
    //  color: #119945;
    //}
  }

  .name {
    font-weight: 600;
  }

  .name-type-digi {
    color: #E87D23;
  }

  .name-type-no-digi-lg {
    color: #348740;
  }
}

.movie {
  .thumbnail {
    position: relative;
    width: 350px;
    margin: 0 auto 20px auto;

    .play-container {
      position: absolute;
      top: 95px;
      left: 155px;

      img {
        width: 350px;
      }

      .button {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 0;

        &:hover {
          background-color: #ff0000;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
        }
      }

      .play.button::before {
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 15px solid white;
        top: 10px;
        left: 16px;
      }
    }
  }
}

.nagrody-img {
  margin: 20px 0;
}

.nagrody-p {
  margin: 25px 0;
}

.logo-abstract {
  display: inline-block;
  height: 260px;
  width: 224px;
  padding: 10px 2px;
  color: @colorBlack;
  border-top: 10px solid @colorBlack;
  border-bottom: 10px solid @colorBlack;
  text-decoration: none !important;

  .wrapper {
    height: 220px;
    width: 220px;
    padding: 65px 40px;

    .text {
      width: 144px;
      height: 85px;
      border-top: 5px solid @colorBlack;
      border-bottom: 5px solid @colorBlack;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;

      .first-line {
        width: 100%;
        border-bottom: 4px solid @colorBlack;
        font-size: 32px;
      }

      .second-line {
        width: 100%;
        font-size: 22px;
      }
    }

    .form {
      .first-line,
      .second-line {
        .form-group {
          margin: 0 !important;
        }

        input {
          padding: 0 !important;
          font-size: 32px;
          font-weight: bold;
          height: 45px;
          border: 1px solid @colorBlack;
          text-align: center;
          background: @colorWhite;
          .placeholder(@colorBlack);
          color: @colorBlack;
        }
      }


      .first-line {
        input {
          border-top-width: 4px;
        }
      }

      .second-line {
        input {
          border-bottom-width: 4px;
        }
      }

      .third-line {
        padding-top: 5px;

        .btn {
          width: 100%;
          text-transform: uppercase;
          background: none;
        }
      }
    }
  }

  &:hover, &:active, &:focus {
    color: @colorBlack;
  }

  &.login {
    height: 340px;
    width: 300px;

    .wrapper {
      height: 300px;
      width: 300px;
      padding: 85px 20px;

      .form {
        input {
          height: 60px;
          font-size: 15px;
        }

        .btn {
          margin-top: 10px;
          font-size: 18px;
        }
      }
    }
  }
}

.modal-backdrop {
  position: fixed;
  height: 100%;
  z-index: 1035;
}

.pull-clear {
  clear: both;
}

.dashboard .cell {
  display: block;
  width: 30%;
  margin: 2% 1%;
  padding: 20px;
  border: 1px solid #d2d2d2;
  text-decoration: none;
  color: #777;

  @media (max-width: 767px) {
    width: 40%;
    margin: 5%;
  }

  &:hover {
    color: #333;
    border: 1px solid #777;
  }
}

.circle-outer {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #F78C00;

  .circle-inner {
    position: absolute;
    left: 5px;
    top: 5px;
    display: table;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 2px #fff solid;

    .text {
      display: table-cell;
      vertical-align: middle;
      color: @colorWhite;
      text-decoration: underline;
      font-weight: 600;
      font-size: 19px;
      line-height: 22px;
    }
  }

  &.circle-big {
    width: 150px;
    height: 150px;

    .circle-inner {
      width: 140px;
      height: 140px;

      .text {
        font-size: 25px;
        line-height: 28px;
      }
    }
  }
}

.center {
  margin: 0 auto;
}

.rounded-border {
  border-width: 5px;
  border-style: solid;
  border-radius: 20px;
}

.rounded-square {
  display: inline-block;
  width: 185px;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;

  &.smaller {
    padding: 8px;
  }
}

.prysnij-na-wiosne-point {
  display: inline-block;
  background-color: #329611;
  color: #fff;
  padding: 4px 12px;
  font-size: 28px;
  font-weight: bold;
}

.countdown {
  .element {
    width: 118px;
    margin: 10px 16px 20px 0;
    .number {
      width: 118px;
      height: 116px;
      font-size: 70px;
      background: url('../images/countdown_element_number.png');
      text-align: center;
      color: #fff;
      display: table-cell;
      vertical-align: middle;
    }
    .name {
      font-weight: bold;
      text-align: center;
    }
  }
}

.progress-product-plans .progress {
  margin-bottom: 0;
  border-radius: 8px;
}

.progress-product-plans .progress-bar {
  background-color: #F6813B;
}

.progress-product-plans .progress-bar-tooltip {
  position: relative;
  left: 100%;
  width: 1px;
}

.progress-product-plans .tooltip {
  z-index: 2;
}

.progress-product-plans .tooltip-inner {
  background-color: #F6813B;
}

.progress-product-plans .tooltip-arrow {
  border-top-color: #F6813B !important;
}

.progress-product-plans .numbers-outer {
  background-color: #F5F5F7;
  border-radius: 8px;
  padding: 5px;
}

.progress-product-plans .numbers-inner {
  padding: 15px;
  border: #F6813B 2px solid;
  border-radius: 8px;
  color: #48484A;
}

.progress-product-plans .numbers-inner dl {
  margin-bottom: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.prizes-new-category {
  padding: 2.5rem;
  margin: 2rem 0;
  background-color: @colorGreen;
  color: @colorWhite;

  img {
    max-width: 70%;
    padding: 1.5rem;
  }
}

.plan-nasiona-wiosna-2023 {
  .plan {
    width: 320px;
    padding: 1rem;
    margin-top: 4rem;
    background-color: #119945;
    color: #fff;
    font-size: 3.5rem;
  }

  .arrows {
    width: 320px;
    margin-bottom: 1.5rem;

    .arrow {
      display: inline-block;
      width: 49%;

      img {
        width: 50px;
      }
    }
  }

  .bg-green {
    background-color: #119945;
    color: #fff;
  }

  .pb-2 {
    padding-bottom: 2rem;
  }

  .info {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .steps {
    margin-bottom: 4rem;

    .title {
      margin-bottom: 2rem;
    }

    .description {
      margin-top: 2rem;
    }
  }

  .statement-form {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #F5F5F5;

    form {
      padding-top: 3rem;
      padding-bottom: 4rem;
    }
  }
}

