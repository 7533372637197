@colorWhite: #ffffff;
@colorLemon: #eeff1f;
@colorViolet: #e726fe;
@colorMagenta: #ff0699;
@colorRedLight: #fd8071;
@colorCyan: #00f6ff;
@colorBlack: #000000;
@colorGrey: #e9eaea;
@colorRed: #ec1313;
@colorGreen: #2ca639;
@colorOrange: #f4af16;
@colorMenu: #2ca639;

.text-success {
  color: @colorMenu;
}
.text-white {
  color: @colorWhite;
}
.text-lemon {
  color: @colorLemon;
}
.text-violet {
  color: @colorViolet;
}
.text-magenta {
  color: @colorMagenta;
}
.text-redlight {
  color: @colorRedLight;
}
.text-cyan {
  color: @colorCyan;
}
.text-black {
  color: @colorBlack;
}
.text-grey {
  color: @colorGrey;
}

.bg-white {
  background: @colorWhite;
}
.bg-lemon {
  background: @colorLemon;
}
.bg-violet {
  background: @colorViolet;
}
.bg-magenta {
  background: @colorMagenta;
}
.bg-redlight {
  background: @colorRedLight;
}
.bg-cyan {
  background: @colorCyan;
}
.bg-black {
  background: @colorBlack;
}
.bg-grey {
  background: @colorGrey;
}