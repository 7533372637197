> .main {
  &.page {
    @media (min-width: @screen-md-min) {
      [ng-view] > .row {
        display: table;
      }
    }
  }

  .sidebar {
    font-size: 12px;
    border-top: 1px solid @colorGrey;

    @media (min-width: @screen-md-min) {
      float: none;
      display: table-cell;
      border-top: 0;
      border-right: 1px solid @colorGrey;
      vertical-align: top;
      padding-top: 20px;
    }

    .banner {
      overflow: hidden;

      a, img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .content {
    padding-bottom: 30px;

    @media (min-width: @screen-md-min) {
      float: none;
      display: table-cell;
      vertical-align: top;
    }
  }

  .agricast {
    display: inline-block;
    width: 110px;
    height: 35px;
    background: transparent url('../images/serwisy/agricast.png') top left;

    &:hover {
      background: transparent url('../images/serwisy/agricast.png') bottom left;
    }
  }

  .infopole {
    display: inline-block;
    width: 95px;
    height: 35px;
    background: transparent url('../images/serwisy/infopole.png') top left;

    &:hover {
      background: transparent url('../images/serwisy/infopole.png') bottom left;
    }
  }

  .weedid {
    display: inline-block;
    width: 95px;
    height: 35px;
    background: transparent url('../images/serwisy/weedid.png') top left;

    &:hover {
      background: transparent url('../images/serwisy/weedid.png') bottom left;
    }
  }
}
